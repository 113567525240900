//Create Slice
import {createSlice} from '@reduxjs/toolkit'

const INITIAL_STATE = ({
    sl_no:1,
    device_type:"",
    device_sl_no:"",
    location:"",
    remarks:"",
    enable: false, 
   edit_enable:false,   
    activate: false,
})

const boqSlice= createSlice({
    name:"boq",
    initialState:INITIAL_STATE,
    reducers:{
        setValues:(state,action)=>{
            const { device_type, device_sl_no, location, remarks, enable, edit_enable, activate } = action.payload;
           // state.sl_no = sl_no;
            state.device_type = device_type;
            state.device_sl_no = device_sl_no;
            state.location = location;
            state.remarks = remarks;
            state.enable = enable;
           // state.edit_enable = edit_enable;
            state.activate = activate;
           
        },


        setEditMode:(state, action) => {
        state.edit_enable = action.payload;
        },


       
            setEnable: (state, action) => {
            state.enable = action.payload;
          },
          setBoqEdit: (state, action)=>{
            state.enable = action.payload;
          },

          setHead:(state,action)=>{
            const { device_type, device_sl_no, location, remarks, activate } = action.payload;
           // state.sl_no = sl_no;
            state.device_type = device_type;
            state.device_sl_no = device_sl_no;
            state.location = location;
            state.remarks = remarks;
            state.activate = activate;
        },

        setHeadFalse: (state, action)=>{
            state.activate = action.payload;
          },

        
       
          
          
    }
})

export const {setValues, setEnable, setHead, setHeadFalse, delVal, setEditMode} = boqSlice.actions
export default boqSlice.reducer