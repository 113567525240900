import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../assets/logo.png'
import logo_white from '../assets/logo-white.png'
import support from '../assets/support-white.png'
import axios from 'axios'

const CustomerField = () => {
    const[formData,setFormData] = useState({
        contractNo:"",
        serialNo:"",
        name:"",
        subject:"",
        emailId:"",
        issueDetails:""
    })

    const handleChange =(e)=>{
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })

    }

    const submitData = async()=>{

        if((formData.contractNo==="")||(formData.serialNo==="")||(formData.subject==="")||(formData.emailId==="")||(formData.issueDetails===""))
            {
            return    
            }
            
           
 
        try{

            const response = await axios.post('/send-email',formData)
           //console.log('Response from server:', response.data);
          //toast.dismiss();
          if(response.data==='success')
           toast.success("Message sent successfully!");
        else
            toast.error("Failed to send message.");

        }catch(error){
            console.log(`Error sending email: ${error}`);
         // toast.dismiss();
          toast.error("Sending Failed..");

        }

        setFormData({
            contractNo:"",
            serialNo:"",
            name:"",
            subject:"",
            emailId:"",
            issueDetails:""
        })
      
    }


  return (
    <>
    <div className='w-full'>
   
        <div className='relative flex mx-auto lg:w-[55rem] lg:h-[35rem] md:w-[45rem] md:h-[35rem] sm:w-[25rem] xs:w-[25rem] border border-stone-200  shadow-lg'>
           <div className='lg:w-80 lg:h-[100vh] md:w-80 md:h-[100vh] sm:w-20 sm:h-[100vh] xs:w-20 xs:h-[100vh]  bg-blue-950  shadow-lg'> 

           
               <div className='flex opacity-10 lg:w-80 lg:h-[30rem] md:w-80 md:h-[30rem]'>
                    <div className=' items-center mx-auto flex'>
                        <img className='lg:w-20 md:w-20 sm:w-0  xs:w-0' src={logo_white} alt='logo-2' />
                        <img className='lg:w-28 md:w-28 sm:w-0  xs:w-0 h-14 mt-6 ml-1' src={support} alt='support' />
                    </div>
               </div>
            </div>

            {/* <div  className='absolute top-0 right-0 p-4'> <img src={logo} alt='logo' className='lg:w-24 md:w-20'/> </div> */}
                <div className='lg:w-96 lg:h-72 md:w-96 md:h-72 sm:w-96 sm:min-h-screen  xs:w-96  xs:min-h-screen  ml-8'>

                <div  className='pt-2'> <img src={logo} alt='logo' className='lg:w-24 md:w-20 sm:w-24  xs:w-24'/> </div>

                    <div className='font-poppins text-sm text-blue-950 pt-3'>Contract No:</div>  
                    <input type='text' name='contractNo' value={formData.contractNo} onChange={handleChange}  className='lg:w-96 lg:h-7 md:w-72 md:h-7 sm:w-60 sm:h-8 xs:w-60 xs:h-8 border border-stone-300 rounded-sm pl-2 mt-1 font-poppins mb-1 text-sm' />

                    <div className='font-poppins text-sm text-blue-950'>Device Sl No:</div>  
                    <input type='text' name='serialNo' value={formData.serialNo} onChange={handleChange} className='lg:w-96 lg:h-7 md:w-72 md:h-7 sm:w-60 sm:h-8 xs:w-60 xs:h-8 border border-stone-300 rounded-sm pl-2 mt-1 font-poppins mb-2 text-sm' />

                    
                    <div className='font-poppins text-sm text-blue-950 mt-4'>Name</div>  
                    <input type='text' name='name' value={formData.name} onChange={handleChange} className='lg:w-96 lg:h-7 md:w-72 md:h-7 sm:w-60 sm:h-8 xs:w-60 xs:h-8 border border-stone-300 rounded-sm pl-2 mt-1 font-poppins mb-2 text-sm' />


                    <div className='font-poppins text-sm text-blue-950'>Subject:</div>  
                    <input type='text' name='subject' value={formData.subject} onChange={handleChange} className='lg:w-96 lg:h-7 md:w-72 md:h-7 sm:w-60 sm:h-8 xs:w-60 xs:h-8 border border-stone-300 rounded-sm pl-2 mt-1 font-poppins mb-2 text-sm' />

                    <div className='font-poppins text-sm text-blue-950'>Email Id:</div>  
                    <input type='email' name='emailId' value={formData.emailId} onChange={handleChange} className='lg:w-96 lg:h-7 md:w-72 md:h-7 sm:w-60 sm:h-8 border border-stone-300 rounded-sm pl-2 mt-1 font-poppins mb-2 text-sm' />

                    <textarea placeholder='Issue details..' name='issueDetails' value={formData.issueDetails} onChange={handleChange} className="lg:w-96 lg:h-20 md:w-72 md:h-20 sm:w-60 sm:h-20 border border-stone-300 rounded-sm pl-2 mt-2 font-poppins text-sm  resize-none"></textarea>
                    <button className='absolute right-36 lg:top-[30rem] md:top-[30rem] sm:top-[32rem] xs:top-[32rem] lg:w-24 lg:h-8 md:w-24 md:h-8 sm:w-28 sm:h-10 border border-blue-950 rounded-md font-poppins text-sm text-blue-950' onClick={submitData}>Submit</button>
                    <ToastContainer />
                </div> 
                  
        </div>
        
        
    </div>
    </>
  )
}

export default CustomerField