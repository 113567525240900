import React,{forwardRef} from 'react'

const InputContract = forwardRef(({ label, type, name, onChange }, ref) => {
  return (
    <div>
      <div className='text-[#172554] font-poppins text-sm mt-8 ml-4'>{label}</div>
      <input type={type} name={name} ref={ref} className='ml-4 lg:w-60 lg:h-7 md:w-48 md:h-7 sm:w-64 sm:h-8 border border-stone-300 rounded-sm pl-2 mt-1 font-poppins mb-1 text-sm' onChange={onChange} />
    </div>
  );
});

export default InputContract