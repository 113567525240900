import React,{act, useEffect, useState, useRef} from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import {useSelector,useDispatch} from 'react-redux'
import { FaCalendarAlt } from 'react-icons/fa';
import BillOfQuantity from './BillOfQuantity';
import BoqHead from './BoqHead';
import InputContract from './InputContract';
import BoqTop from './BoqTop';

import { v4 as uuidv4 } from 'uuid';
import SidePanel from './SidePanel';
const AdminPage = () => {
  const [mainHeads,setMainHeads] = useState([])
  const [boqArray, setBoqArray] = useState([]);// Boq will be stored on this
  const [editData, setEditData] = useState()
  const[sendId,setSendId] = useState()
  // const [formValues, setFormValues] = useState({ 
  //   device_type: "",
  //   device_sl_no:"",
  //   location: "",
  //   remarks: "", 
  // })
const contractRef = useRef() 
const bpRef = useRef() 
const contactPersonRef = useRef() 
const emailRef = useRef()
const endUserRef = useRef()
const startDateRef = useRef()
const endDateRef = useRef()
const typeRef = useRef()

const getData = (datas)=>{
  setBoqArray((prev)=>
    [...prev, {
      ...datas,
      id: uuidv4(),
    },])
}
  const deleteBoq = (id) => {
  setBoqArray(boqArray.filter(boq => boq.id !== id));
  };

    const editBoq = (id)=>{
    const boq = boqArray.find((item) => item.id === id);  
    setSendId(id) 
    setEditData(boq)
    }

    const updateContent = (formValues,editId)=>{
      setBoqArray(boqArray.filter(boq => boq.id !== editId));
      setBoqArray((prev)=>
        [...prev, {
          ...formValues,
          id: uuidv4(),
        },])
    }


    const handleInputChange = () => {
      console.log(contractRef.current.value);
      console.log(startDateRef.current.value);
    };

  return (
    <>
    <div className='w-full flex lg:flex-row md:flex-row sm:flex-col xs:flex-col '> 

    
       
    {/* <div className='lg:h-0 md:h-0 sm:h-14 text-white font-poppins font-light text-xl pl-4 pt-2 pb-2 bg-blue-950'>
    
          
     </div>     */}
      
        
      <SidePanel/>
       
            <div className='relative lg:ml-28 md:ml-28 mx-auto lg:w-auto lg:h-[100hv] md:w-auto md:h-[35rem] sm:w-[25rem] sm:h-[70rem] xs:w-[25rem] xs:h-[70rem] border border-stone-200  shadow-lg'>

              
              <div className='flex flex-wrap lg:mt-2 md:mt-2 sm:mt-20  xs:mt-20'> 
            
                      <InputContract ref={contractRef} label='Contract No:' type='text' name='contractNo' onChange={handleInputChange}/>
                      <InputContract ref={bpRef} label='Business Partner Name:' type='text' name='bpname' onChange={handleInputChange}/>
                      <InputContract ref={contactPersonRef} label='Contact Person' type='name' name='contactperson' onChange={handleInputChange}/>
                      <InputContract ref={emailRef} label='Email Address' type='email' name='email' onChange={handleInputChange}/>
                      <InputContract ref={endUserRef} label='End User' type='name' name='enduser' onChange={handleInputChange}/>
                      <InputContract ref={startDateRef} label='Contract Start Date' type='date' name='startdate' onChange={handleInputChange}/>
                      <InputContract ref={endDateRef} label='Contract Expiry Date:' type='date' name='endDate' onChange={handleInputChange}/>
                      <InputContract ref={typeRef} label='SLA Type' type='text' name='slaType' onChange={handleInputChange}/>    
              

                    <div className='w-full h-72 mt-2 border border-stone-200 shadow-lg'>
                      <div className='text-[#172554] font-poppins text-lg mt-2 ml-4'>BOQ Entry</div>
                        <div className='flex lg:flex-row md:flex-row sm:flex-col w-auto'> 

                          <div>
                            <BoqHead sendData={getData} injectData={editData} updateData={updateContent} editId={sendId}/> 
                          </div>

                                
                                <div className='w-auto h-60 border border-stone-300 shadow-lg overflow-y-auto overflow-x-hidden'>
                                  
                                      <BoqTop/>
                                  

                                  <div>
                                      {boqArray.map((boq, index) => (
                                          <div  key={index} className='w-[28rem] border border-stone-200'>
                                            <BillOfQuantity sl_no={index+1} {...boq} onDelete={() => deleteBoq(boq.id)} onEdit={()=>editBoq(boq.id)}/>
                                          </div> 
                                          ))}
                                  </div> 

                                                          
                                </div> 

                                <div className='flex flex-row ml-4 lg:mt-44 md:mt-44 sm:mt-4 xs:mt-4'>
                                      <button className='mr-4 lg:w-28 lg:h-8 md:w-28 md:h-8 sm:w-28 sm:h-10 xs:w-28 xs:h-10 mb-4 bg-blue-950 rounded-md font-poppins text-xs text-white'>Save Contract</button>
                                      
                                    </div>      
                        </div>
                    </div>

              </div>             

            </div>
        
        
    </div>

   
    
    </>
  )
}

export default AdminPage