import React,{useState, useEffect} from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import CustomerField from './components/CustomerField'
import LoginAdmin from './components/LoginAdmin'
import Signup from './components/Signup'
import AdminPage from './components/AdminPage';

import axios from 'axios';
/*
const ProtectedRoute = ({ element: Component, ...rest }) => {
  const token = localStorage.getItem('authToken');
  return token ? <Component {...rest} /> : <Navigate to="/login" />;
};*/

const App = () => {




  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setAuthenticated(false);
        return;
      }

      try {
        const response = await axios.post('/api/check-auth', { token }); // Example endpoint to check auth
        if (response.data.authenticated) {
          setAuthenticated(true);
        } else {
          setAuthenticated(false);
          localStorage.removeItem('authToken'); // Remove invalid token from localStorage
        }
      } catch (error) {
        setAuthenticated(false);
        localStorage.removeItem('authToken'); // Remove invalid token from localStorage
      }
    };

    checkAuth();
  }, []);




  return (
    <>  
    
    <BrowserRouter>
      <Routes>
         <Route path="/" element={<AdminPage />} /> 
         <Route path="/client" element={<CustomerField />} />   
         <Route path="/login" element={<LoginAdmin />} />        
         <Route path="/signup" element={<Signup />} />        
       </Routes>
     </BrowserRouter>
     </>
  )
}
export default App
