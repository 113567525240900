import React,{useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import logo from '../assets/logo.png'
import logo_white from '../assets/logo-white.png'
import axios from 'axios'
import support from '../assets/support-white.png'
import { VscAccount } from "react-icons/vsc";
import { CiMail } from "react-icons/ci";
import { GoLock } from "react-icons/go";
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";
const adminIcon = <VscAccount size={55} color='#172554'/>
const mailIcon = <CiMail  size={25} color='#172554'/>
const passwordIcon =<GoLock  size={25} color='#172554'/>
const eyeIcon = <FaRegEye size={20} color='#172554'/>
const eyeClose = <FaRegEyeSlash size={18} color='#172554'/>

const Signup = () => {
const[visibleIcon,setVisibleIcon] = useState(true)
const [formData, setFormData] = useState({
    email: '',
    password: '',
});

const navigate = useNavigate();

const changeVisibility = ()=>{
    setVisibleIcon((prev)=>!prev)  
}


const handleChange = (e) => {
    
    setFormData({
        ...formData,
        [e.target.name]: e.target.value,
    });
};


const handleSubmit = async (e) => {
    e.preventDefault();
  setFormData({
        email: '',
        password: '',
        });

        
        try {
            const response = await axios.post('/api/signup', formData);           
           

           if(response.data === "email Id already Exits"){
            toast.error("Email Id already Exists!");
           }
           else if(response.data === "signup success"){
            toast.success("Sign Up Successfull!"); 
            setTimeout(() => {
                navigate('/login'); // Redirect to the login page after a short delay
              }, 3000);   
           }
            
        } catch (error) {
            toast.error("Sign Up Error!");
            console.log(error);
    }
  


};

  return (
    <>
    <div className='w-full'>
   
    <div className='relative flex mx-auto lg:w-[50rem] lg:h-[30rem] md:w-[40rem] md:h-[30rem] sm:w-[25rem] sm:h-[100vh] border border-stone-200  shadow-lg'>
       <div className='lg:w-80 lg:h-[30rem] md:w-80 md:h-[30rem] sm:w-20  bg-blue-950  shadow-lg'>
           <div className='flex opacity-10 w-80 h-[30rem]'>
               <div className=' items-center mx-auto flex'>
                   <img className='lg:w-28 md:w-20 sm:w-0' src={logo_white} alt='logo-2' />                   
               </div>
           </div>
       </div>

       {/* <div  className='absolute top-0 right-0 p-4'> <img src={logo} alt='logo' className='lg:w-24 md:w-20'/> </div> */}
           

           <div  className='absolute top-0 right-0'> <img src={logo} alt='logo' className='lg:w-24 md:w-20 sm:w-24'/> </div>
         <form onSubmit={handleSubmit} className='w-full'>
            <div className='flex items-center justify-center h-full'>
                    <div className='flex flex-col items-center justify-center lg:w-80 h-96 border border-blue-100 rounded-lg lg:ml-12 md:ml-12 sm:ml-4 shadow-lg'>
                      
                      
                    <div className='w-28 lg:ml-12 md:ml-12 sm:ml-4'>
                             {adminIcon}
                        </div>
                       
                        <div className='flex relative lg:w-72 lg:h-10 md:w-72 md:h-10 sm:w-72 sm:h-8'>
                            <div className='absolute left-0 top-0 flex items-center h-full pl-2 lg:pt-10 md:pt-10 sm:pt-9'>{mailIcon}</div> 
                                 
                            <input type='text'name='email' className='lg:w-72 lg:h-10 md:w-72 md:h-10 sm:w-64 sm:h-8 border border-blue-950  rounded-sm pl-10 mt-5 font-poppins mb-1 text-sm' value={formData.email} onChange={handleChange}/>
                        </div>

                        <div className='flex relative lg:w-72 lg:h-10 md:w-72 md:h-10 sm:w-72 sm:h-8 mt-2'> 
                            <div className='absolute left-0 top-0 flex items-center h-full pl-2 lg:pt-10 md:pt-10 sm:pt-9' onClick={changeVisibility}>{visibleIcon? eyeClose : eyeIcon}</div>      
                            <input type={visibleIcon ? 'password' : 'text'} name='password' className='lg:w-72 lg:h-10 md:w-72 md:h-10 sm:w-64 sm:h-8 border border-blue-950  rounded-sm pl-10 mt-5 font-poppins text-sm' value={formData.password} onChange={handleChange}/>
                        </div>

                        <button className='mt-7 lg:w-72 lg:h-10 md:w-24 md:h-10 sm:w-28 sm:h-10 border border-blue-950 rounded-md font-poppins text-sm text-white bg-blue-950' type='submit'>Sign Up</button>
                               
                        <div className='flex mt-2'>
                                     <div className='lg:ml-14 md:ml-14 sm:ml-2 font-poppins text-[12px]'>Already have an Account?</div>  <Link to='/login' className='text-[#172554] font-poppins text-[12px] pl-1'>Log In</Link>
                                </div> 
                      <ToastContainer /> 
                    </div>
                    
                </div>
          
            </form>   
   </div>
   
   
</div>

    </>
  )
}

export default Signup