import React from 'react'
import logo from '../assets/logo-white.png'
import support from '../assets/support-white.png'

const SidePanel = () => {
  return (
    <>
  
    <div className='fixed top-0 left-0 lg:w-28 lg:h-[100vh] md:w-28 md:h-[100vh] sm:w-full sm:h-20 xs:w-full xs:h-20 bg-blue-950 z-10 shadow-lg'>
        <div className='flex lg:ml-3 md:ml-3 sm:ml-3 xs:ml-3'>
        <div>
        <img className='w-7 mt-4' src={logo} alt='logo'/>
        </div>
        <div>
        <img className='w-14 mt-5 ml-1' src={support} alt='logo'/>
        </div>
        </div>
    <div className='lg:w-24 sm:hidden md:block lg:block'>
     <div className='text-white font-poppins text-xs ml-2 mt-5 font-light lg:border lg:border-blue-900 md:border md:border-blue-900 rounded-sm w-24 h-6 text-left pt-1 pl-1 '>Admin Panel</div>
     <div className='text-white font-poppins text-xs ml-2 mt-1 font-light lg:border lg:border-blue-900 md:border md:border-blue-900 rounded-sm w-24 h-6 text-left pt-1 pl-1'>Tickets</div>
     <div className='text-white font-poppins text-xs ml-2 mt-1 font-light lg:border lg:border-blue-900 md:border md:border-blue-900 rounded-sm w-24 h-6 text-left pt-1 pl-1'>Contracts</div>
     <div className='text-white font-poppins text-xs ml-2 mt-1 font-light lg:border lg:border-blue-900 md:border md:border-blue-900 rounded-sm w-24 h-6 text-left pt-1 pl-1'>Bill of Quantity</div>
     </div>     
   </div>
   
    </>
  )
}

export default SidePanel